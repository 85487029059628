import { Button, Modal, Steps, Form, Radio, Space, Input } from 'antd';
import React, { useState } from 'react';
import { useApi } from '../hooks/useApi';
import { CarrierLogin } from '../types';

export const MFAModal: React.FC<{ login: CarrierLogin; reloadLogins: () => Promise<void> }> = ({
  login,
  reloadLogins,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [current, setCurrent] = useState(0);
  const { callApi: selectContactMethod, loading: selectContactLoading } = useApi(
    `/portal-logins/${login.id}/contact-method`,
    'PUT',
    false,
  );
  const {
    callApi: enterCode,
    loading: enterCodeLoading,
    error,
    errorType,
  } = useApi(`/portal-logins/${login.id}/enter-code`, 'PUT', false);
  const [form] = Form.useForm();
  if (error && errorType === useApi.errorTypes.CLIENT_ERROR) {
    form.setFields([
      {
        name: 'code',
        errors: ['invalid code'],
      },
    ]);
  }
  return (
    <>
      <Button onClick={() => setModalOpen(true)}>Fix</Button>
      <Modal visible={modalOpen} footer={null} title="Link account" onCancel={() => setModalOpen(false)} destroyOnClose>
        <Steps current={current} style={{ paddingBottom: '30px' }}>
          <Steps.Step title="Select Contact Method"></Steps.Step>
          <Steps.Step title="Enter Code"></Steps.Step>
        </Steps>
        {current === 0 && (
          <Form
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={async (data) => {
              await selectContactMethod({ data });
              setCurrent(1);
            }}
          >
            <Form.Item label="Select method" name="contactMethod" rules={[{ required: true }]}>
              <Radio.Group>
                <Space direction="vertical">
                  {login.contactMethods.map((method) => (
                    <Radio value={method} key={method}>
                      {method}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit" loading={selectContactLoading}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        )}
        {current === 1 && (
          <Form
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            form={form}
            onFinish={async (data) => {
              await enterCode({ data });
              await reloadLogins();
              setModalOpen(false);
            }}
          >
            <Form.Item name="code" label="Code" required>
              <Input />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit" loading={enterCodeLoading}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  );
};
