import { Button, Form, Input, Modal, PageHeader, Select, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { get } from 'lodash';
import { useState } from 'react';
import { useAuthContext } from '../components/AuthProvider';
import { MFAModal } from '../components/MFAModal';
import { SecurityQuestionModal } from '../components/SecurityQuestionModal';
import { UpdatePortalPasswordModal } from '../components/UpdatePortalPasswordModal';
import { useApi } from '../hooks/useApi';
import { Agency, CarrierLogin } from '../types';

export const CarriersPage = () => {
  const { callApi: getLogins, data, loading } = useApi('/portal-logins', 'GET', true);
  const {
    callApi: addLogin,
    loading: createLoading,
    error: createError,
    errorType: createErrorType,
  } = useApi('/portal-logins', 'POST', false);
  const { data: agencyResponse, loading: agencyLoading } = useApi('/agencies', 'GET', true);
  const agencies = get(agencyResponse, 'data', []) as Agency[];
  const [modalVisible, setModalVisible] = useState(false);
  const { portalSlugs } = useAuthContext();
  const columns: ColumnsType<CarrierLogin> = [
    { title: 'Id', dataIndex: 'id' },
    { title: 'Carrier', dataIndex: 'portalSlug' },
    { title: 'Agency', dataIndex: ['agency', 'name'] },
    { title: 'Username', dataIndex: 'username' },
    { title: 'Status', dataIndex: 'status' },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => {
        if (record.status === 'MFA_CHECK') {
          return record.canFullyAutomateMfa ? (
            <Tooltip title="No need to fix manually, this will be updated automatically">
              <Button disabled={true}>Fix</Button>
            </Tooltip>
          ) : (
            <MFAModal login={record} reloadLogins={getLogins} />
          );
        } else if (record.status === 'SECURITY_QUESTION_CHECK') {
          return <SecurityQuestionModal login={record} reloadLogins={getLogins} />;
        }
        return (
          <UpdatePortalPasswordModal
            id={record.id}
            carrier={record.portalSlug}
            reloadLogins={getLogins}
            status={record.status}
          />
        );
      },
    },
  ];

  const items = data as unknown as { data: CarrierLogin[] };

  const [form] = Form.useForm();
  if (createError && createErrorType === useApi.errorTypes.CLIENT_ERROR) {
    console.log(createError);
    form.setFields([
      {
        name: 'password',
        errors: ['invalid credentials'],
      },
      {
        name: 'username',
        errors: ['invalid credentials'],
      },
    ]);
  }
  return (
    <PageHeader
      title="Linked Carriers"
      extra={[
        <Button
          type="primary"
          key="add"
          onClick={() => {
            setModalVisible(true);
          }}
        >
          Add
        </Button>,
      ]}
    >
      <Table
        dataSource={get(items, 'data', []).filter((login) => login.status !== 'INACTIVE')}
        loading={loading}
        columns={columns}
        rowKey="id"
      ></Table>
      <Modal
        title="Add Carrier"
        visible={modalVisible}
        confirmLoading={createLoading}
        onCancel={() => setModalVisible(false)}
        onOk={async () => {
          const data = await form.validateFields();
          await addLogin({ data });
          await getLogins();
          form.resetFields();
          setModalVisible(false);
        }}
      >
        {!agencyLoading && (
          // Hacky way to deal with options loaded from the db, will break if the agencies endpoint does
          <Form form={form} initialValues={{ agencyId: agencies[0].id }}>
            <Form.Item label="Agency" name="agencyId" rules={[{ required: true }]} hidden={agencies.length === 1}>
              <Select
                options={agencies.map((agency) => ({
                  label: agency.name,
                  value: agency.id,
                }))}
              />
            </Form.Item>
            <Form.Item
              label="Carrier Portal"
              name="carrierSlug"
              rules={[{ required: true, message: 'Please select a portal' }]}
            >
              <Select showSearch options={portalSlugs.sort().map((slug) => ({ label: slug, value: slug }))}></Select>
            </Form.Item>
            <Form.Item
              label="Username"
              name="username"
              rules={[{ required: true, message: 'Please input your username!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password />
            </Form.Item>
          </Form>
        )}
      </Modal>
    </PageHeader>
  );
};
